export const HOME_ACTION1_ACTION = "@HOME/ACTION1";
export const HOME_ACTION2_ACTION = "@HOME/ACTION2";

export const APP_HANDLE_REDIRECT_ACTION = "@APP/HANDLE_REDIRECT";
export const APP_HANDLE_REDIRECT_DONE_ACTION = "@APP/HANDLE_REDIRECT_DONE";

export const APP_TOKEN_REFRESH_ACTION = "@APP/TOKEN_REFRESH";
export const APP_TOKEN_REFRESH_DONE_ACTION = "@APP/TOKEN_REFRESH_DONE";

export const APP_MAGIC_LINK_ACTION = "@MAGIC_LINK/LOAD_ACTION";
export const APP_MAGIC_LINK_DONE_ACTION = "@MACIG_LINK/LOAD_DONE_ACTION";

export const APP_GRAPH_API_ACTION = "@GRAPH_API/LOAD_ACTION";
export const APP_GRAPH_API_DONE_ACTION = "@GRAPH_API/LOAD_DONE_ACTION";

export const APP_REFRESH_TOKEN_GRAPH_ACTION = "@GRAPH_API/REFRESH_TOKEN_ACTION";
export const APP_REFRESH_TOKEN_GRAPH_DONE_ACTION =
	"@GRAPH_API/REFRESH_TOKEN_DONE_ACTION";

export const APP_REFRESH_MAIN_TOKEN = "@APP/REFRESH_MAIN_TOKEN";
