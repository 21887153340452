import { Observable, from, of } from "rxjs";
import { catchError, exhaustMap, map } from "rxjs/operators";

import { APP_MAGIC_LINK_ACTION } from "../constants";
import { Actions } from "../actions";
import { MagicLinkLoadDoneAction } from "../../../redux/home/actions/magicLink.actions";
import { magicLinkProvider } from "../../../providers/magicLink.provider";
import { ofType } from "redux-observable";

export const MagicLinkLoadEpic = (
	action$: Observable<Actions>
): Observable<any> =>
	action$.pipe(
		ofType(APP_MAGIC_LINK_ACTION),
		exhaustMap((action) => {
			return from(magicLinkProvider.post(action.payload as any)).pipe(
				map((result) => {
					const { response } = result as any;
					const { message } = response;
					return new MagicLinkLoadDoneAction(`${message}`).create();
				}),
				catchError((error) =>
					of(new MagicLinkLoadDoneAction(error).create())
				)
			);
		})
	);
