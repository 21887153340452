import { GraphApiLoadEpic } from './graphApi.epics';
import { HandleRedirectActionEpic } from './app.epics';
import { MagicLinkLoadEpic } from './magicLink.epics';
import { RefreshTokenActionEpic } from './token.epics';
import { combineEpics } from "redux-observable";

const HomeEpics = combineEpics(
	HandleRedirectActionEpic,
	RefreshTokenActionEpic,
	MagicLinkLoadEpic,
	GraphApiLoadEpic
);
export default HomeEpics;
