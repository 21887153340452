import { MSAL_ACCESS_TOKEN, MSAL_CURRENT_USERNAME, MSAL_ID_TOKEN } from './../../utils/constants';

import AuthToken from "../../utils/auth-token";
import { Msal } from "../../components/iam-azure-provider/msal";

export type HomeState = {
	isLoading: boolean;
	msalInstance?: Msal;
	idToken?: string;
	accessToken?: string;
	username?: string;
	idTokenDecode?: any;
	isLogged: boolean;
	message?: string;
	graphCode?: string;
};

export const HomeinitialState: HomeState = {
	isLoading: false,
	isLogged: false,
	idToken: AuthToken.getToken(MSAL_ID_TOKEN) || "",
	accessToken: AuthToken.getToken(MSAL_ACCESS_TOKEN) || "",
	username: AuthToken.getToken(MSAL_CURRENT_USERNAME) || "",
};
