import {ACCESS_DENIED, SESSION_EXPIRED} from "../../utils/constants";
import {AzureContext, AzurePrivateRoute} from "@pacificoseguros/security";
import {
    ROUTE_APP_ERROR,
    ROUTE_APP_ERROR_UNAUTHORIZED,
    ROUTE_APP_HOME,
    ROUTE_APP_LANDING,
} from "../../constants/routes/app";
import React, {Suspense, lazy, useContext} from "react";
import {Route, Switch} from "react-router-dom";

import Loading from "../../components/loading";

const Principal = lazy(() => import("../principal/principal"));
const Landing = lazy(() => import("../landing/landing"));
const ErrorPage = lazy(() => import("../../components/error/index"));

export const AppRoutes: React.FC = () => {
    const {msalInstance, isLoading} = useContext(AzureContext);
    return !msalInstance || isLoading ? (
        <Loading />
    ) : (
        <Suspense fallback={<Loading />}>
            <Switch>
                <AzurePrivateRoute path={ROUTE_APP_HOME} component={Principal} />
                <Route exact path={ROUTE_APP_LANDING} component={Landing} />
                <Route
                    path={ROUTE_APP_ERROR}
                    render={() => (
                        <ErrorPage message={SESSION_EXPIRED} btnLogout={true} />
                    )}
                />
                <Route
                    path={ROUTE_APP_ERROR_UNAUTHORIZED}
                    render={() => <ErrorPage message={ACCESS_DENIED} btnLogout={false} />}
                />
                <Route path="*" component={Landing} />
            </Switch>
        </Suspense>
    );
};
