import { ajax, AjaxResponse } from "rxjs/ajax";
import { Observable } from "rxjs";
import { Environment } from "@pacificoseguros/tools";

class Api {
	/**
	 * getUrl
	 * * Retorna el hostserver  concatenated with the custom url
	 * ? All class have to extends from Api
	 * @param url this param is the url to concatenated
	 * @param host this param is optional for custom hostserver
	 */
	static getUrl(url: string, host: string): string {
		return `${host}/${url}`;
	}

	static getHeader(): { "Content-Type": string; Accept: string } {
		return {
			"Content-Type": "application/json",
			Accept: "application/json",
		};
	}

	static getFileHeader(): { Accept: string } {
		return {
			Accept: "*/*",
		};
	}

	static getPrivateHeader(
		authorization = ""
	): {
		"Content-Type": string;
		Accept: string;
		Authorization: string;
	} {
		return {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: authorization,
		};
	}

	static getJSON<T>(
		url: string,
		{
			params = {},
			isPublicRequest = true,
			host = process.env.REACT_APP_SERVER_URL,
			customHeader = null,
		}: IRequestInfo
	): Observable<T> {
		return ajax.getJSON(
			Api.getUrl(
				`${url}?${new URLSearchParams(params).toString()}`,
				host ?? ""
			),
			customHeader
				? customHeader
				: isPublicRequest
				? Api.getHeader()
				: Api.getPrivateHeader()
		);
	}

	static get<T>(
		url: string,
		{
			params = {},
			isPublicRequest = true,
			host = process.env.REACT_APP_SERVER_URL,
			customHeader = null,
			isAbsoluteUrl = false,
			data = {},
		}: IRequestInfo
	): Observable<T> {
		return (ajax({
			url: isAbsoluteUrl
				? url
				: Api.getUrl(
						`${url}?${new URLSearchParams(params).toString()}`,
						host ?? ""
				  ),
			headers: customHeader
				? customHeader
				: isPublicRequest
				? Api.getHeader()
				: Api.getPrivateHeader(),
			method: "GET",
			body: JSON.stringify(data),
		}) as unknown) as Observable<T>;
	}

	static post<T>(
		url: string,
		{
			data = {},
			isPublicRequest = true,
			host = process.env.REACT_APP_SERVER_URL,
			customHeader = null,
			isAbsoluteUrl = false,
		}: IRequestInfo
	): Observable<T> {
		return (ajax.post(
			isAbsoluteUrl ? url : Api.getUrl(url, host ?? ""),
			data,
			customHeader
				? customHeader
				: isPublicRequest
				? Api.getHeader()
				: Api.getPrivateHeader()
		) as unknown) as Observable<T>;
	}

	static put<T>(
		url: string,
		{
			data = {},
			isPublicRequest = true,
			host = process.env.REACT_APP_SERVER_URL,
			customHeader = null,
			isAbsoluteUrl = false,
		}: IRequestInfo
	): Observable<T> {
		return (ajax.put(
			isAbsoluteUrl ? url : Api.getUrl(url, host ?? ""),
			data,
			customHeader
				? customHeader
				: isPublicRequest
				? Api.getHeader()
				: Api.getPrivateHeader()
		) as unknown) as Observable<T>;
	}

	static delete(
		url: string,
		{
			isPublicRequest = true,
			host = Environment.string(process.env.REACT_APP_SERVER_URL, ""),
			customHeader = null,
		}: IRequestInfo
	): Observable<AjaxResponse> {
		return ajax.delete(
			Api.getUrl(url, host),
			customHeader
				? customHeader
				: isPublicRequest
				? Api.getHeader()
				: Api.getPrivateHeader()
		);
	}
}

export default Api;
