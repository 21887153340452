/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import "./error-fallback.scss";
type Props = {
	error: Error;
	resetErrorBoundary: any;
};

const ErrorFallback: React.FC<Props> = (props: Props) => {
	return (
		<div role="alert">
			<p>Something went wrong:</p>
			<pre>{props.error.message}</pre>
			<button onClick={props.resetErrorBoundary}>Try again</button>
		</div>
	);
};

export default ErrorFallback;
