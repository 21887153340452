import "./assets/styles/index.css";
import "@pacificoseguros/pixie/variables.css";

import App from "./containers/app/app";
import React from "react";
import ReactDOM from "react-dom";
import { defineCustomElements } from "@pacificoseguros/pixie/loader";
import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
defineCustomElements(window);