import Api from "./provider/api";
import { GRAPH_ENDPOINT } from "../providers/provider/api/constants";
import { Observable } from "rxjs";

export class GraphApiProvider {
	get(data: { accessToken: string }): Observable<IAjaxResponse<string>> {
		const { accessToken } = data;
		return Api.get(GRAPH_ENDPOINT, {
			isPublicRequest: true,
			customHeader: {
				...Api.getHeader(),
				Authorization: `Bearer ${accessToken}`,
				"Ocp-Apim-Subscription-Key": "d7067485ce2d417698e40935fef18456",
			},
			isAbsoluteUrl: true,
		});
	}
}
export const graphApiProvider = new GraphApiProvider();
