import "./styles/app.scss";

import {AzureProvider, Msal, StorageType} from "@pacificoseguros/security";
import {ROUTE_APP_LANDING} from "../../constants/routes/app";

import {AppRoutes} from "./app.routes";
import {Environment} from "@pacificoseguros/tools";
import {ErrorBoundary} from "react-error-boundary";
import ErrorFallback from "../../components/error-fallback";
import {Provider} from "react-redux";
import React from "react";
import {HashRouter, BrowserRouter} from "react-router-dom";
import store from "../../redux/store";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";

const hasBrowserHistory = Environment.bool(process.env.REACT_APP_HAS_BROWSER_ROUTER);
const Router = hasBrowserHistory ? (BrowserRouter as any) : (HashRouter as any);
const routerProps = hasBrowserHistory
    ? {basename: Environment.string(process.env.REACT_APP_PUBLIC_URL, "/")}
    : {};

export const msal = new Msal({
    clientId: Environment.string(process.env.REACT_APP_CLIENT_ID),
    authority: Environment.string(process.env.REACT_APP_AUTHORITY),
    redirectUri: Environment.string(process.env.REACT_APP_REDIRECT_URI),
    storage: StorageType.localStorage,
    redirectDefault: ROUTE_APP_LANDING,
});

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});

const App: React.FC = () => {
    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <AzureProvider msalInstance={msal}>
                <Provider store={store}>
                    <QueryClientProvider client={queryClient}>
                        <div className="app" data-testid="app">
                            <Router {...routerProps}>
                                <AppRoutes />
                            </Router>
                        </div>
                    </QueryClientProvider>
                </Provider>
            </AzureProvider>
        </ErrorBoundary>
    );
};

export default App;
