import {applyMiddleware, legacy_createStore} from "redux";
import epicMiddleware, {rootEpic} from "./epics";
import {composeWithDevTools} from "redux-devtools-extension";
import rootReducer from "./reducers";

const composeEnhancer = composeWithDevTools({
    name: "React Clean Architecture",
});

const store = legacy_createStore(
    rootReducer,
    {},
    composeEnhancer(applyMiddleware(epicMiddleware))
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
epicMiddleware.run(rootEpic as any);

export default store;
