import { combineReducers } from "redux";
import { HomeReducer } from "./home/reducer";
import { HomeinitialState, HomeState } from "./home/state";

export default combineReducers({ home: HomeReducer });

export const inititalRootState: RootState = {
	home: HomeinitialState,
};

export type RootState = {
	home: HomeState;
};
