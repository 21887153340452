import { Observable, empty, from, of } from "rxjs";
import { StateObservable, ofType } from "redux-observable";
import { catchError, exhaustMap, map } from "rxjs/operators";

import { APP_TOKEN_REFRESH_ACTION } from './../constants';
import { Actions } from "../actions";
import { Msal } from "../../../components/iam-azure-provider/msal";
import { RefreshTokenDoneAction } from './../actions/token.actions';
import { RootState } from './../../reducers';

export const RefreshTokenActionEpic = (
	action$: Observable<Actions>,
	store: StateObservable<RootState>,
	scopes: Array<string>
): Observable<any> =>
	action$.pipe(
		ofType(APP_TOKEN_REFRESH_ACTION),
		exhaustMap((action) => {
			const msalInstance: Msal = store.value.home.msalInstance as Msal;
			const account = msalInstance.getAccountByUserName(
				action.payload as string
			);
			if (account)
				return from(msalInstance.refreshToken(account, scopes)).pipe(
					map((response) => {
						return new RefreshTokenDoneAction(
							(response as any).accessToken as string
						).create();
					}),
					catchError((error) =>
						of(new RefreshTokenDoneAction(error).create())
					)
				);
			else return empty();
		})
	);
