import { Action } from "@pacificoseguros/tools";
import {
	APP_MAGIC_LINK_ACTION,
	APP_MAGIC_LINK_DONE_ACTION,
} from "../constants";
import { HomeState } from "../state";

export class MagicLinkLoadAction extends Action<
	{ documentType: string; documentNumber: string; accessToken: string },
	HomeState
> {
	readonly type = APP_MAGIC_LINK_ACTION;
	reducer(state: HomeState): HomeState {
		return {
			...state,
			isLoading: true,
		};
	}
}

export class MagicLinkLoadDoneAction extends Action<string, HomeState> {
	readonly type = APP_MAGIC_LINK_DONE_ACTION;
	reducer(state: HomeState): HomeState {
		if (this.error) {
			return {
				...state,
				isLoading: false,
			};
		} else {
			return {
				...state,
				message: this.payload || "",
				isLoading: true,
			};
		}
	}
}
