import { MSAL_ACCESS_TOKEN, MSAL_CURRENT_USERNAME, MSAL_ID_TOKEN } from './../../../utils/constants';
import { Observable, from, of } from "rxjs";
import { catchError, exhaustMap, map } from "rxjs/operators";

import { APP_HANDLE_REDIRECT_ACTION } from './../constants';
import { Actions } from './../actions/index';
import AuthToken from "../../../utils/auth-token"
import { HandleRedirectDoneAction } from './../actions/app.actions';
import { Msal } from "../../../components/iam-azure-provider/msal";
import { ofType } from "redux-observable";

export const HandleRedirectActionEpic = (
	action$: Observable<Actions>
): Observable<any> =>
	action$.pipe(
		ofType(APP_HANDLE_REDIRECT_ACTION),
		exhaustMap((action) => {
			const msalInstance: Msal = action.payload as Msal;
			return from(msalInstance.handleRedirect()).pipe(
				map((response) => {
					const { idToken, accessToken } = response as any;
					AuthToken.setToken(MSAL_ACCESS_TOKEN, accessToken);
					AuthToken.setToken(MSAL_ID_TOKEN, idToken);
					AuthToken.setToken(
						MSAL_CURRENT_USERNAME,
						response?.account?.username
					);

					return new HandleRedirectDoneAction(response).create();
				}),
				catchError((error) =>
					of(new HandleRedirectDoneAction(error).create())
				)
			);
		})
	);
