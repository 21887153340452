import {
	APP_TOKEN_REFRESH_ACTION,
	APP_TOKEN_REFRESH_DONE_ACTION,
} from "../constants";

import { Action } from "@pacificoseguros/tools";
import { HomeState } from './../state';

export class RefreshTokenAction extends Action<string, HomeState> {
	readonly type = APP_TOKEN_REFRESH_ACTION;
	reducer(state: HomeState): HomeState {
		return {
			...state,
		};
	}
}

export class RefreshTokenDoneAction extends Action<string, HomeState> {
	readonly type = APP_TOKEN_REFRESH_DONE_ACTION;
	reducer(state: HomeState): HomeState {
		if (this.error) {
			return {
				...state,
			};
		} else {
			return {
				...state,
				accessToken: this.payload as string,
			};
		}
	}
}
