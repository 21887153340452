import Api from "./provider/api";
import { MAGIC_LINK_ENDPOINT } from "../providers/provider/api/constants";
import { Observable } from "rxjs";

export class MagicLinkProvider {
	post(data: {
		documentType: string;
		documentNumber: string;
		accessToken: string;
	}): Observable<IAjaxResponse<string>> {
		const { accessToken, ...body } = data;
		return Api.post(MAGIC_LINK_ENDPOINT, {
			isPublicRequest: true,
			data: body,
			customHeader: {
				...Api.getHeader(),
				Authorization: `Bearer ${accessToken}`,
				"Ocp-Apim-Subscription-Key": "d7067485ce2d417698e40935fef18456",
			},
			isAbsoluteUrl: true,
		});
	}
}
export const magicLinkProvider = new MagicLinkProvider();
