const store = localStorage;
class AuthToken {
	static getToken(KEY_TOKEN) {
		return store.getItem(KEY_TOKEN);
	}

	static setToken(keyToken, token) {
		if (token) store.setItem(keyToken, token);
		else store.removeItem(keyToken);
	}
}

export default AuthToken;
