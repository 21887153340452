import {
	     APP_HANDLE_REDIRECT_ACTION,
	     APP_HANDLE_REDIRECT_DONE_ACTION
} from './../constants';

import { Action } from "@pacificoseguros/tools";
import { AuthenticationResult } from "@azure/msal-browser";
import { HomeState } from "./../state";
import { Msal } from "../../../components/iam-azure-provider/msal";

export class HandleRedirectAction extends Action<Msal, HomeState> {
	readonly type = APP_HANDLE_REDIRECT_ACTION;
	reducer(state: HomeState): HomeState {
		return {
			...state,
			msalInstance: this.payload as Msal,
			isLoading: true,
		};
	}
}

export class HandleRedirectDoneAction extends Action<
	AuthenticationResult | Error,
	HomeState
> {
	readonly type = APP_HANDLE_REDIRECT_DONE_ACTION;
	reducer(state: HomeState): HomeState {
		if (!this.error) {
			const result = this.payload as AuthenticationResult;
			return {
				...state,
				isLoading: false,
				idToken: result.idToken,
				accessToken: result.accessToken,
				username: result.account?.username,
				isLogged: true,
			};
		} else return { ...state, isLoading: false };
	}
}
