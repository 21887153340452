import { APP_GRAPH_API_ACTION, APP_GRAPH_API_DONE_ACTION } from "../constants";

import { Action } from "@pacificoseguros/tools";
import { HomeState } from "../state";

export class GraphApiLoadAction extends Action<
	{ accessToken: string },
	HomeState
> {
	readonly type = APP_GRAPH_API_ACTION;
	reducer(state: HomeState): HomeState {
		return {
			...state,
			isLoading: true,
		};
	}
}

export class GraphApiLoadDoneAction extends Action<string, HomeState> {
	readonly type = APP_GRAPH_API_DONE_ACTION;
	reducer(state: HomeState): HomeState {
		if (this.error) {
			return {
				...state,
				isLoading: false,
			};
		} else {
			return {
				...state,
				graphCode: this.payload || "",
				isLoading: true,
			};
		}
	}
}
