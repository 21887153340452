import { Actions } from "./actions";
import { HomeinitialState, HomeState } from "./state";

export function HomeReducer(
	state = HomeinitialState,
	action: Actions
): HomeState {
	if (action.instance) return action?.instance?.reducer(state);
	else return state;
}
