export const MSAL_ACCESS_TOKEN_GRAPH_API = "msal.access.token.graph.api";
export const MSAL_ACCESS_TOKEN = "msal.access.token";
export const MSAL_ID_TOKEN = "msal.idtoken";
export const MSAL_CURRENT_USERNAME = "msal.current.userName";
export const MSAL_GRAPH_API_TOKEN = "msal.graphApi.token";

export const ACCESS_DENIED = "El usuario no cuenta con permiso al aplicativo";
export const NOT_AVAILABLE =
	"Parece que el servicio no esta disponible por ahora";
export const SESSION_EXPIRED = "Su sesion ha expirado";

export const copyInfo = (data: any) => {
	navigator.clipboard.writeText(data);
};

export const loginRequestScope: Array<string> = [
	process.env.REACT_APP_MSAL_REFRESH_TOKEN || "",
	process.env.REACT_APP_MSAL_PRODUCT_SCOPE || "",
];

export const refreshTokenScope: Array<string> = [
	process.env.REACT_APP_MSAL_REFRESH_TOKEN || "",
];

export const graphApiRequestScope: Array<string> = [
	process.env.REACT_APP_MSAL_GRAPH_SCOPE || "",
	process.env.REACT_APP_MSAL_REFRESH_TOKEN || "",
];

export const apiRequestScope: Array<string> = [
	process.env.REACT_APP_MSAL_PRODUCT_SCOPE || "",
	process.env.REACT_APP_MSAL_REFRESH_TOKEN || "",
];
