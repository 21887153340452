import "./loading.scss";

import React from "react";

export default function Loading() {
    return (
        <div data-testid="component_loading" className="component_loading">
        loading
    </div>
    );
}
