import { Observable, from, of } from "rxjs";
import { catchError, exhaustMap, map } from "rxjs/operators";

import { APP_GRAPH_API_ACTION } from "../constants";
import { Actions } from "../actions";
import { GraphApiLoadDoneAction } from "../../../redux/home/actions/graphApi.actions";
import { graphApiProvider } from "../../../providers/graphApi.provider";
import { ofType } from "redux-observable";

export const GraphApiLoadEpic = (
	action$: Observable<Actions>
): Observable<any> => {
	return action$.pipe(
		ofType(APP_GRAPH_API_ACTION),
		exhaustMap((action) => {
			return from(graphApiProvider.get(action.payload as any)).pipe(
				map((result) => {
					return new GraphApiLoadDoneAction(result.response).create();
				}),
				catchError((error) =>
					of(new GraphApiLoadDoneAction(error).create())
				)
			);
		})
	);
};
